<template>
    <div> <img src="@/assets/solutionpdf/build_1.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_2.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_3.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_4.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_5.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_6.jpg" alt="" />
    <img src="@/assets/solutionpdf/build_7.jpg" alt="" />
    </div>
</template>

<script>
export default {
    name: "build-solution"
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>